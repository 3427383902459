<template>
  <section>
    <b-row>
      <b-col lg="4" cols="12">
        <b-card>
          <b-card-text>
            <p class="text-uppercase custom-text-disabled">Details</p>
            <b-list-group flush>
              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Name: <span class="text-body-2">{{ department?.name }}</span>
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Head: <span class="text-body-2">{{ department?.head?.data?.name }}</span>
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Division: <span class="text-body-2">{{ department?.division?.data?.name }}</span>
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Created ON: <span class="text-body-2">{{ department?.created_at }}</span>
              </h6></b-list-group-item
              >

            </b-list-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="8" cols="12">
        <b-card>
          <div>
            <!-- search input -->
            <div
              class="custom-search d-flex align-items-center mb-1 justify-content-between"
            >
                <b-form-input
                  v-on:keyup="onSearch"
                  v-model.lazy="searchTerm"
                  placeholder="Search..."
                  type="text"
                  class="d-inline-block mr-sm-1 mb-1 mb-sm-0 col-3"
                />

                <template
                  v-if="
                    $permissionAbility(DEPARTMENT_EMPLOYEE_ADD, permissions)
                  "
                >
                  <b-button
                    class="flex-shrink-0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-on:click="showModal"
                  >
                    Add Employee
                  </b-button>
                </template>
            </div>

            <!-- table -->
            <vue-good-table
              :line-numbers="false"
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :rows="rows"
              :sort-options="{
                enabled: true,
                multipleColumns: true,
                initialSortBy: [
                  { field: 'id', type: 'desc' },
                  { field: 'name', type: 'desc' },
                  { field: 'created_at', type: 'desc' },
                ],
              }"
              :columns="columns"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              styleClass="vgt-table table-custom-style striped"
            >
              <template slot="table-row" slot-scope="props">

                <!-- Column: head -->
                <span v-if="props.column.field === 'format_name'">

                  <template v-if="props.row?.id">

                  <div class="d-flex align-items-center">
                    <b-avatar :src="props?.row?.avatar" class="mr-1">
                    </b-avatar>
                    <div>
                      <div class="font-weight-bolder">
                        {{ props?.row?.name }}
                        <b-badge pill variant="light-secondary">{{
                            props?.row?.employee_number
                          }}</b-badge>
                      </div>
                      <a :href="'mailto:' + (props?.row?.email || '')" class="font-small-2 font-weight-bold">
                        {{ props?.row?.email }}
                      </a>
                      <div class="font-small-3">
                        <a :href="'tel:' + (props?.row?.mobile || '')">
                          <b-badge variant="light-primary">
                            {{ props?.row?.mobile }}
                          </b-badge>
                          </a>
                      </div>
                    </div>
                  </div>

              </template>

                </span>

                <!-- Column: Status -->
                <span v-if="props.column.field === 'employee_status'">
                  <b-badge :variant="statusVariant(props.row?.status)">
                    {{ props.row.status ? "Active" : "Inactive" }}
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-if="props.column.field === 'action'">

                  <template v-if="props?.row?.status">
                      <template v-if="$permissionAbility(DEPARTMENT_EMPLOYEE_INACTIVE, permissions)">
                    <span @click="onInactive(props.row?.id)">
                      <feather-icon
                          v-b-tooltip.hover
                          icon="XIcon"
                          class="mr-50 custom-icon cursor-pointer"
                          title="Inactive"
                          size="16"
                      />
                    </span>
                  </template>
                  </template>
                  <template v-else>
                      <template v-if="$permissionAbility(DEPARTMENT_EMPLOYEE_ACTIVE, permissions)">
                    <span @click="onActive(props.row?.id)">
                      <feather-icon
                          v-b-tooltip.hover
                          icon="CheckIcon"
                          class="mr-50 custom-icon cursor-pointer"
                          title="Active"
                          size="16"
                      />
                    </span>
                  </template>
                  </template>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '25', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>

          <b-modal
            id="modal-department-show-form"
            centered
            title="Add Employee"
            hide-footer
            @hidden="hiddenModal"
            no-close-on-backdrop
          >
            <validation-observer ref="departmentUserValidation">
              <b-form v-on:submit.prevent="validationForm">
                <!-- select Division -->
                <b-form-group label="Employee" label-for="user-id">
                  <ValidationProvider
                    name="user_id"
                    v-slot="{ errors }"
                    vid="user_id"
                  >
                    <v-select
                      id="user-id"
                      placeholder="Search a employee"
                      v-model="selectUserId"
                      :options="usersOption"
                      :reduce="(option) => option.id"
                      label="name"
                      :filterable="false"
                      @search="onSearchUsers"
                    >
                      <template #option="data">
                        <div class="d-flex align-items-center">
                          <b-avatar :src="data?.avatar === '' ? '/avatar.svg' : data?.avatar" class="mr-1">
                          </b-avatar>
                          <div>
                            <div class="font-weight-bolder">
                              {{ data?.name }}
                              <b-badge pill variant="warning">{{
                                  data?.employee_number
                                }}</b-badge>
                            </div>
                            <div class="font-small-2">
                              {{ data?.email }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>

                <!-- loading button -->
                <template v-if="isDepartmentUserFormSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-form>
            </validation-observer>
          </b-modal>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BCol,
  BRow,
  BCardText,
  BSpinner, BListGroupItem, BListGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  DEPARTMENT_EMPLOYEE_ADD,
  DEPARTMENT_EMPLOYEE_ACTIVE,
  DEPARTMENT_EMPLOYEE_INACTIVE,
} from "@/helpers/permissionsConstant";

export default {
  name: "DepartmentDetailsView",
  components: {
    BListGroup,
    BListGroupItem,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BRow,
    BCardText,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      DEPARTMENT_EMPLOYEE_ADD,
      DEPARTMENT_EMPLOYEE_ACTIVE,
      DEPARTMENT_EMPLOYEE_INACTIVE,
      selectUserId: "",
      usersOption: [],
      pageLength: 10,
      columns: [
        {
          label: "Name",
          field: "format_name",
        },
        {
          label: "Designation",
          field: "designation.data.name",
          sortable: false,
        },
        {
          label: "Status",
          field: "employee_status",
          sortable: false,
        },
        {
          label: "Created On",
          field: "created_at",
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isDepartmentUserFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
      department: {},
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      const id = this.$route.params.id;
      const resDepartment = await this.$api.get(
        `/api/departments/${id}?include=head,division`
      );

      const dataDepartment = resDepartment?.data?.data;
      this.department = dataDepartment;
      await this.getEmployees()
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  watch: {
    selectUserId(newData, oldData) {
      if (newData) {
        const departmentOption = this.usersOption.find(
          (item) => item.id == newData
        ).department;

        if (departmentOption) {
          this.$swal({
            title: "Warning!",
            text: "Your selected user has a department do you want to change department?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then((result) => {
            if (result.isDismissed) {
              this.resetModal();
            }
          });
        }
      }
    },
  },

  methods: {
    onSearchUsers(search, loading) {
      if (search.length) {
        const id = this.$route.params.id;
        loading(true);
        if (this.delayTimer) {
          clearTimeout(this.delayTimer);
          this.delayTimer = null;
        }

        this.delayTimer = setTimeout(async () => {
          const resUsers = await this.$api.get(
            `api/department/${id}/users/search?include=department`,
            {
              params: {
                q: search,
              },
            }
          );

          const dataUsers = resUsers?.data?.data;
          console.log(dataUsers)
          this.usersOption = (dataUsers || []).map((item) => {
            return {
              avatar: item?.avatar,
              name: item?.name,
              email: item?.email,
              mobile: item?.mobile,
              employee_number: item?.employee_number,
              id: item?.id,
              departmentId: item?.department_id,
            };
          });
          loading(false);
        }, 800);
      }
    },

    showModal() {
      this.$bvModal.show("modal-department-show-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-department-show-form");
      this.resetModal();
    },
    resetModal() {
      this.selectUserId = "";
      this.usersOption = [];
    },
    async onActive(id) {
      try {
        await this.$api.post("api/departments/user-active", {
          user_id: id,
        });

        this.loadItems();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Successfully Active",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async onInactive(id) {
      try {
        await this.$api.post("api/departments/user-inactive", {
          user_id: id,
        });

        this.loadItems();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Successfully Inactive",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    // async onDelete(id) {
    //   try {
    //     await this.$api.post("/api/sync-user-department", {
    //       user_id: id,
    //     });

    //     this.loadItems();

    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: "Success",
    //         icon: "BellIcon",
    //         variant: "success",
    //         text: "Successfully Deleted",
    //       },
    //     });
    //   } catch (error) {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: "Error",
    //         icon: "BellIcon",
    //         variant: "danger",
    //         text: error?.response?.data?.message,
    //       },
    //     });
    //   }
    // },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async loadItems() {
      const id = this.$route.params.id;
      try {
        const resDepartmentUsers = await this.$api.get(
          `/api/department/${id}/users?include=designation`,
          {
            params: {
              show: this.serverParams.perPage,
              page: this.serverParams.page,
              sort: this.serverParams.sort,
              q: this.searchTerm,
            },
          }
        );

        const data = resDepartmentUsers?.data?.data;
        const meta = resDepartmentUsers?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;

        await this.getEmployees();

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async getEmployees() {
      try {
        const employees = await this.$api.get('api/users/all');
        this.usersOption = (employees?.data?.data || []).map(item => ({
          avatar: item?.avatar,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          employee_number: item?.employee_number,
          id: item?.id,
          departmentId: item?.department_id,
        }));
      } catch (error) {
        console.error('Error fetching employees:', error);
        this.usersOption = [];
      }
    },

    validationForm: async function () {
      this.$refs.departmentUserValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isDepartmentUserFormSubmitLoading = true;
            const departmentId = this.$route.params.id;
            await this.$api.post("/api/departments/user-add", {
              department_id: departmentId,
              user_id: this.selectUserId,
            });
            this.isDepartmentUserFormSubmitLoading = false;
            this.hiddenModal();

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Add",
              },
            });
          } catch (error) {
            this.isDepartmentUserFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.departmentUserValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
