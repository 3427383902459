var render = function () {
  var _vm$department, _vm$department2, _vm$department2$head, _vm$department2$head$, _vm$department3, _vm$department3$divis, _vm$department3$divis2, _vm$department4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4",
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-card-text', [_c('p', {
    staticClass: "text-uppercase custom-text-disabled"
  }, [_vm._v("Details")]), _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Name: "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s((_vm$department = _vm.department) === null || _vm$department === void 0 ? void 0 : _vm$department.name))])])]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Head: "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s((_vm$department2 = _vm.department) === null || _vm$department2 === void 0 ? void 0 : (_vm$department2$head = _vm$department2.head) === null || _vm$department2$head === void 0 ? void 0 : (_vm$department2$head$ = _vm$department2$head.data) === null || _vm$department2$head$ === void 0 ? void 0 : _vm$department2$head$.name))])])]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Division: "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s((_vm$department3 = _vm.department) === null || _vm$department3 === void 0 ? void 0 : (_vm$department3$divis = _vm$department3.division) === null || _vm$department3$divis === void 0 ? void 0 : (_vm$department3$divis2 = _vm$department3$divis.data) === null || _vm$department3$divis2 === void 0 ? void 0 : _vm$department3$divis2.name))])])]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Created ON: "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s((_vm$department4 = _vm.department) === null || _vm$department4 === void 0 ? void 0 : _vm$department4.created_at))])])])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "8",
      "cols": "12"
    }
  }, [_c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center mb-1 justify-content-between"
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0 col-3",
    attrs: {
      "placeholder": "Search...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  }), _vm.$permissionAbility(_vm.DEPARTMENT_EMPLOYEE_ADD, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Add Employee ")])] : _vm._e()], 2), _c('vue-good-table', {
    attrs: {
      "line-numbers": false,
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: true,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }, {
          field: 'name',
          type: 'desc'
        }, {
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      },
      "styleClass": "vgt-table table-custom-style striped"
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row2, _props$row3, _props$row4, _props$row5, _props$row6, _props$row7, _props$row8, _props$row9, _props$row10;
        return [props.column.field === 'format_name' ? _c('span', [(_props$row = props.row) !== null && _props$row !== void 0 && _props$row.id ? [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-avatar', {
          staticClass: "mr-1",
          attrs: {
            "src": props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.avatar
          }
        }), _c('div', [_c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.name) + " "), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-secondary"
          }
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.employee_number))])], 1), _c('a', {
          staticClass: "font-small-2 font-weight-bold",
          attrs: {
            "href": 'mailto:' + ((props === null || props === void 0 ? void 0 : (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : _props$row5.email) || '')
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : _props$row6.email) + " ")]), _c('div', {
          staticClass: "font-small-3"
        }, [_c('a', {
          attrs: {
            "href": 'tel:' + ((props === null || props === void 0 ? void 0 : (_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : _props$row7.mobile) || '')
          }
        }, [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : _props$row8.mobile) + " ")])], 1)])])], 1)] : _vm._e()], 2) : _vm._e(), props.column.field === 'employee_status' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": _vm.statusVariant((_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : _props$row9.status)
          }
        }, [_vm._v(" " + _vm._s(props.row.status ? "Active" : "Inactive") + " ")])], 1) : _vm._e(), props.column.field === 'action' ? _c('span', [props !== null && props !== void 0 && (_props$row10 = props.row) !== null && _props$row10 !== void 0 && _props$row10.status ? [_vm.$permissionAbility(_vm.DEPARTMENT_EMPLOYEE_INACTIVE, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              var _props$row11;
              return _vm.onInactive((_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : _props$row11.id);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "XIcon",
            "title": "Inactive",
            "size": "16"
          }
        })], 1)] : _vm._e()] : [_vm.$permissionAbility(_vm.DEPARTMENT_EMPLOYEE_ACTIVE, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              var _props$row12;
              return _vm.onActive((_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : _props$row12.id);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "CheckIcon",
            "title": "Active",
            "size": "16"
          }
        })], 1)] : _vm._e()]], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-department-show-form",
      "centered": "",
      "title": "Add Employee",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "departmentUserValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Employee",
      "label-for": "user-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "user_id",
      "vid": "user_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          attrs: {
            "id": "user-id",
            "placeholder": "Search a employee",
            "options": _vm.usersOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "filterable": false
          },
          on: {
            "search": _vm.onSearchUsers
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                staticClass: "mr-1",
                attrs: {
                  "src": (data === null || data === void 0 ? void 0 : data.avatar) === '' ? '/avatar.svg' : data === null || data === void 0 ? void 0 : data.avatar
                }
              }), _c('div', [_c('div', {
                staticClass: "font-weight-bolder"
              }, [_vm._v(" " + _vm._s(data === null || data === void 0 ? void 0 : data.name) + " "), _c('b-badge', {
                attrs: {
                  "pill": "",
                  "variant": "warning"
                }
              }, [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : data.employee_number))])], 1), _c('div', {
                staticClass: "font-small-2"
              }, [_vm._v(" " + _vm._s(data === null || data === void 0 ? void 0 : data.email) + " ")])])], 1)];
            }
          }], null, true),
          model: {
            value: _vm.selectUserId,
            callback: function callback($$v) {
              _vm.selectUserId = $$v;
            },
            expression: "selectUserId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isDepartmentUserFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }